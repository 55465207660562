import React from 'react';
import textRuleData from '@openameba/textlint-rule-preset-ameba/lib/prh-service.yml';

import * as style from './ServiceRelatedTermsList.module.css';

type LintListItem = {
  expected: string;
  specs: {
    from: string;
    to: string;
  }[];
};

type TermsListItem = {
  approved: string;
  deprecated: string[];
};

type ServiceTermsItem = { [key: string]: string[] };

export default function ServiceRelatedTermsList() {
  // テキストの整形
  const termsList = textRuleData.rules.map((data: LintListItem) => {
    return {
      approved: data.expected,
      deprecated: data.specs.map((spec) => spec.from),
    };
  });

  // 重複したテキストをマージ
  const serviceTermsList = termsList.reduce(
    (serviceTermsListItem: ServiceTermsItem, termsListItem: TermsListItem) => {
      const { approved, deprecated } = termsListItem;

      if (serviceTermsListItem[approved]) {
        serviceTermsListItem[approved] = [
          ...serviceTermsListItem[approved],
          ...deprecated,
        ];
        return serviceTermsListItem;
      }

      serviceTermsListItem[approved] = deprecated;
      return serviceTermsListItem;
    },
    {},
  );

  return (
    <table className={style.table}>
      <thead>
        <tr>
          <th>推奨</th>
          <th>非推奨</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(serviceTermsList).map((key) => (
          <tr key={key}>
            <td>{key}</td>
            <td>{serviceTermsList[key].join('、')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
