import React from 'react';

import * as style from './PageTitle.module.css';

interface Props {
  title: string;
  enTitle?: string;
}

const PageTitle: React.VFC<Props> = ({ title, enTitle }) => {
  return (
    <header className={style.root}>
      <h1 className={style.title}>{title}</h1>
      {enTitle && <p className={style.enTitle}>{enTitle}</p>}
    </header>
  );
};

export default PageTitle;
