// extracted by mini-css-extract-plugin
export var enTitle = "PanelNav-module--enTitle--b3200";
export var header = "PanelNav-module--header--9b95a";
export var icon = "PanelNav-module--icon--0ecda";
export var item = "PanelNav-module--item--74523";
export var lead = "PanelNav-module--lead--bf844";
export var list = "PanelNav-module--list--55afa";
export var name = "PanelNav-module--name--29e75";
export var root = "PanelNav-module--root--92d25";
export var summary = "PanelNav-module--summary--2eae6";
export var title = "PanelNav-module--title--15964";