import React from 'react';
import CheckCircleFill from '@openameba/spindle-ui/Icon/CheckCircleFill';
import ChevronRightBold from '@openameba/spindle-ui/Icon/ChevronRightBold';

import * as style from './ComponentStatus.module.css';

type Status = 'inProgress' | 'examRelease' | 'notCompleted' | 'completed';

type StatusList = {
  heading: string;
  headingLink?: string;
  design: Status;
  site: Status;
  web: Status;
  ios: Status;
  android: Status;
}[];

export const componentStatusList: StatusList = [
  {
    heading: 'AppealModal',
    design: 'completed',
    site: 'notCompleted',
    web: 'examRelease',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'BottomButton',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'BreadCrumb',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'Button',
    headingLink: '/components/button',
    design: 'completed',
    site: 'completed',
    web: 'completed',
    ios: 'completed',
    android: 'completed',
  },
  {
    heading: 'ButtonGroup',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'Checkbox',
    headingLink: '/components/checkbox',
    design: 'completed',
    site: 'completed',
    web: 'completed',
    ios: 'completed',
    android: 'completed',
  },
  {
    heading: 'Dialog',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'completed',
    android: 'completed',
  },
  {
    heading: 'DropDown',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'DropDownMenu',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'DropShadow',
    design: 'completed',
    site: 'notCompleted',
    web: 'notCompleted',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'HeroCarousel',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'Icon',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'IconButton',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'examRelease',
    android: 'examRelease',
  },
  {
    heading: 'InlineDropDown',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'examRelease',
  },
  {
    heading: 'InlineNotification',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'InputLabel',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'InvalidMessage',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'LinkButton',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'examRelease',
    android: 'notCompleted',
  },
  {
    heading: 'List',
    headingLink: '/components/list',
    design: 'completed',
    site: 'completed',
    web: 'notCompleted',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'Modal',
    headingLink: '/components/modal',
    design: 'completed',
    site: 'completed',
    web: 'notCompleted',
    ios: 'completed',
    android: 'completed',
  },
  {
    heading: 'MoreLink',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'Pagination',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'SegmentedControl',
    design: 'completed',
    site: 'notCompleted',
    web: 'examRelease',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'SemiModal',
    design: 'completed',
    site: 'notCompleted',
    web: 'examRelease',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'SnackBar',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'completed',
    android: 'completed',
  },
  {
    heading: 'StackNotificationManager',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'SubtleButton',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'TextArea',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'completed',
    android: 'completed',
  },
  {
    heading: 'TextButton',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'TextField',
    headingLink: '/components/textfield',
    design: 'completed',
    site: 'completed',
    web: 'completed',
    ios: 'completed',
    android: 'completed',
  },
  {
    heading: 'TextLink',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'Toast',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'notCompleted',
    android: 'notCompleted',
  },
  {
    heading: 'ToggleSwitch',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'completed',
    android: 'completed',
  },
  {
    heading: 'Radio',
    design: 'completed',
    site: 'notCompleted',
    web: 'completed',
    ios: 'examRelease',
    android: 'completed',
  },
];

interface Props {
  status: Status;
}

const TableData: React.VFC<Props> = ({ status }) => {
  switch (status) {
    case 'completed':
      return (
        <td>
          <CheckCircleFill className={style.iconCheck} aria-label="完了" />
        </td>
      );
    case 'inProgress':
      return <td>進行中</td>;
    case 'examRelease':
      return (
        <td>
          <b className={style.bold}>試験公開</b>
          <CheckCircleFill className={style.iconCheck} aria-hidden />
        </td>
      );
    default:
      return <td>-</td>;
  }
};

export default function ComponentStatus() {
  return (
    <div className={style.scroll}>
      <table className={style.table}>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Design</th>
            <th scope="col">Site</th>
            <th scope="col">Web</th>
            <th scope="col">iOS</th>
            <th scope="col">Android</th>
          </tr>
        </thead>
        <tbody>
          {componentStatusList.map((item) => {
            const { heading, headingLink, design, site, web, ios, android } =
              item;
            return (
              <tr key={heading}>
                {headingLink ? (
                  <th scope="row">
                    <a className={style.link} href={headingLink}>
                      {heading}
                      <ChevronRightBold className={style.iconChevron} />
                    </a>
                  </th>
                ) : (
                  <th scope="row">{heading}</th>
                )}
                <TableData status={design} />
                <TableData status={site} />
                <TableData status={web} />
                <TableData status={ios} />
                <TableData status={android} />
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
