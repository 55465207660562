import React from 'react';
import { Link } from 'gatsby';

import * as style from './PanelNav.module.css';

type PanelNavList = {
  url: string;
  name: string;
  summary: string;
};

interface Props {
  title: string;
  enTitle?: string;
  icon: JSX.Element;
  lead: string;
  list: PanelNavList[];
}

const PanelNav: React.VFC<Props> = ({ title, enTitle, icon, lead, list }) => {
  return (
    <section className={style.root}>
      <header className={style.header}>
        <h3 className={style.title}>
          <span className={style.icon}>{icon}</span>
          {title}
        </h3>
        <p className={style.enTitle}>{enTitle}</p>
      </header>
      <p className={style.lead}>{lead}</p>
      <ul className={style.list}>
        {list.map((item) => (
          <li key={item.name}>
            <Link to={item.url} className={style.item}>
              <h4 className={style.name}>{item.name}</h4>
              <div className={style.summary}>
                <p>{item.summary}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default PanelNav;
