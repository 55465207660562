import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
}

const SEO: React.VFC<Props> = ({ title, description, image, article }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery<GatsbyTypes.SEOQuery>(query);
  const siteMetadata = site?.siteMetadata;

  const pageDescription = description || siteMetadata?.defaultDescription;
  const pageImage = siteMetadata
    ? `${siteMetadata.siteUrl}${image || siteMetadata.defaultImage}`
    : undefined;
  const canonicalUrl = siteMetadata
    ? `${siteMetadata.siteUrl}${pathname}`
    : undefined;

  return (
    <Helmet
      defaultTitle={siteMetadata?.defaultTitle}
      titleTemplate={siteMetadata?.titleTemplate}
    >
      {title && <title>{title}</title>}
      <meta name="description" content={pageDescription} />
      <meta name="image" content={pageImage} />

      <link rel="canonical" href={canonicalUrl} />

      <meta property="og:url" content={canonicalUrl} />
      {article ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}
      <meta property="og:title" content={title || siteMetadata?.defaultTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={pageImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={title || siteMetadata?.defaultTitle}
      />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={pageImage} />
    </Helmet>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
