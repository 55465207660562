// extracted by mini-css-extract-plugin
export var button = "BrandMovie-module--button--7b366";
export var iconOpenBlank = "BrandMovie-module--iconOpenBlank--0ba99";
export var iconPause = "BrandMovie-module--iconPause--23193";
export var iconPlay = "BrandMovie-module--iconPlay--d627d";
export var labelHidden = "BrandMovie-module--labelHidden--ce9ec";
export var linkButtonInverse = "BrandMovie-module--linkButtonInverse--41435";
export var linkWrap = "BrandMovie-module--linkWrap--92163";
export var logo = "BrandMovie-module--logo--f5311";
export var logoWrap = "BrandMovie-module--logoWrap--4c7e0";
export var pauseHidden = "BrandMovie-module--pauseHidden--61a35";
export var playHidden = "BrandMovie-module--playHidden--3fcc3";
export var root = "BrandMovie-module--root--f990b";
export var video = "BrandMovie-module--video--7c6ed";
export var videoWrap = "BrandMovie-module--videoWrap--a69d0";