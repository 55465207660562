exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-mdx": () => import("./../../../src/pages/about/index.mdx" /* webpackChunkName: "component---src-pages-about-index-mdx" */),
  "component---src-pages-about-mediakit-mdx": () => import("./../../../src/pages/about/mediakit.mdx" /* webpackChunkName: "component---src-pages-about-mediakit-mdx" */),
  "component---src-pages-brand-about-mdx": () => import("./../../../src/pages/brand/about.mdx" /* webpackChunkName: "component---src-pages-brand-about-mdx" */),
  "component---src-pages-brand-ameba-brand-brand-guidelines-mdx": () => import("./../../../src/pages/brand/ameba-brand/brand-guidelines.mdx" /* webpackChunkName: "component---src-pages-brand-ameba-brand-brand-guidelines-mdx" */),
  "component---src-pages-brand-ameba-brand-index-mdx": () => import("./../../../src/pages/brand/ameba-brand/index.mdx" /* webpackChunkName: "component---src-pages-brand-ameba-brand-index-mdx" */),
  "component---src-pages-brand-ameba-brand-resources-mdx": () => import("./../../../src/pages/brand/ameba-brand/resources.mdx" /* webpackChunkName: "component---src-pages-brand-ameba-brand-resources-mdx" */),
  "component---src-pages-brand-ameba-brand-slide-templates-mdx": () => import("./../../../src/pages/brand/ameba-brand/slide-templates.mdx" /* webpackChunkName: "component---src-pages-brand-ameba-brand-slide-templates-mdx" */),
  "component---src-pages-brand-ameba-brand-vision-and-mission-mdx": () => import("./../../../src/pages/brand/ameba-brand/vision-and-mission.mdx" /* webpackChunkName: "component---src-pages-brand-ameba-brand-vision-and-mission-mdx" */),
  "component---src-pages-brand-index-mdx": () => import("./../../../src/pages/brand/index.mdx" /* webpackChunkName: "component---src-pages-brand-index-mdx" */),
  "component---src-pages-components-button-mdx": () => import("./../../../src/pages/components/button.mdx" /* webpackChunkName: "component---src-pages-components-button-mdx" */),
  "component---src-pages-components-checkbox-mdx": () => import("./../../../src/pages/components/checkbox.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../../../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-components-list-mdx": () => import("./../../../src/pages/components/list.mdx" /* webpackChunkName: "component---src-pages-components-list-mdx" */),
  "component---src-pages-components-modal-mdx": () => import("./../../../src/pages/components/modal.mdx" /* webpackChunkName: "component---src-pages-components-modal-mdx" */),
  "component---src-pages-components-status-mdx": () => import("./../../../src/pages/components/status.mdx" /* webpackChunkName: "component---src-pages-components-status-mdx" */),
  "component---src-pages-components-textfield-mdx": () => import("./../../../src/pages/components/textfield.mdx" /* webpackChunkName: "component---src-pages-components-textfield-mdx" */),
  "component---src-pages-employees-index-mdx": () => import("./../../../src/pages/employees/index.mdx" /* webpackChunkName: "component---src-pages-employees-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-principles-accessibility-examples-mdx": () => import("./../../../src/pages/principles/accessibility/examples.mdx" /* webpackChunkName: "component---src-pages-principles-accessibility-examples-mdx" */),
  "component---src-pages-principles-accessibility-index-mdx": () => import("./../../../src/pages/principles/accessibility/index.mdx" /* webpackChunkName: "component---src-pages-principles-accessibility-index-mdx" */),
  "component---src-pages-principles-accessibility-resources-mdx": () => import("./../../../src/pages/principles/accessibility/resources.mdx" /* webpackChunkName: "component---src-pages-principles-accessibility-resources-mdx" */),
  "component---src-pages-principles-contents-brand-voice-mdx": () => import("./../../../src/pages/principles/contents/brand-voice.mdx" /* webpackChunkName: "component---src-pages-principles-contents-brand-voice-mdx" */),
  "component---src-pages-principles-contents-index-mdx": () => import("./../../../src/pages/principles/contents/index.mdx" /* webpackChunkName: "component---src-pages-principles-contents-index-mdx" */),
  "component---src-pages-principles-contents-ui-labeling-mdx": () => import("./../../../src/pages/principles/contents/ui-labeling.mdx" /* webpackChunkName: "component---src-pages-principles-contents-ui-labeling-mdx" */),
  "component---src-pages-principles-contents-vocabulary-mdx": () => import("./../../../src/pages/principles/contents/vocabulary.mdx" /* webpackChunkName: "component---src-pages-principles-contents-vocabulary-mdx" */),
  "component---src-pages-principles-design-index-mdx": () => import("./../../../src/pages/principles/design/index.mdx" /* webpackChunkName: "component---src-pages-principles-design-index-mdx" */),
  "component---src-pages-principles-index-mdx": () => import("./../../../src/pages/principles/index.mdx" /* webpackChunkName: "component---src-pages-principles-index-mdx" */),
  "component---src-pages-principles-performance-checklist-mdx": () => import("./../../../src/pages/principles/performance/checklist.mdx" /* webpackChunkName: "component---src-pages-principles-performance-checklist-mdx" */),
  "component---src-pages-principles-performance-how-to-mdx": () => import("./../../../src/pages/principles/performance/how-to.mdx" /* webpackChunkName: "component---src-pages-principles-performance-how-to-mdx" */),
  "component---src-pages-principles-performance-index-mdx": () => import("./../../../src/pages/principles/performance/index.mdx" /* webpackChunkName: "component---src-pages-principles-performance-index-mdx" */),
  "component---src-pages-principles-performance-resources-mdx": () => import("./../../../src/pages/principles/performance/resources.mdx" /* webpackChunkName: "component---src-pages-principles-performance-resources-mdx" */),
  "component---src-pages-style-mdx": () => import("./../../../src/pages/style.mdx" /* webpackChunkName: "component---src-pages-style-mdx" */),
  "component---src-pages-styles-animation-index-mdx": () => import("./../../../src/pages/styles/animation/index.mdx" /* webpackChunkName: "component---src-pages-styles-animation-index-mdx" */),
  "component---src-pages-styles-animation-property-mdx": () => import("./../../../src/pages/styles/animation/property.mdx" /* webpackChunkName: "component---src-pages-styles-animation-property-mdx" */),
  "component---src-pages-styles-animation-type-mdx": () => import("./../../../src/pages/styles/animation/type.mdx" /* webpackChunkName: "component---src-pages-styles-animation-type-mdx" */),
  "component---src-pages-styles-color-brand-mdx": () => import("./../../../src/pages/styles/color/brand.mdx" /* webpackChunkName: "component---src-pages-styles-color-brand-mdx" */),
  "component---src-pages-styles-color-index-mdx": () => import("./../../../src/pages/styles/color/index.mdx" /* webpackChunkName: "component---src-pages-styles-color-index-mdx" */),
  "component---src-pages-styles-color-primitive-colors-mdx": () => import("./../../../src/pages/styles/color/primitive-colors.mdx" /* webpackChunkName: "component---src-pages-styles-color-primitive-colors-mdx" */),
  "component---src-pages-styles-color-theme-colors-mdx": () => import("./../../../src/pages/styles/color/theme-colors.mdx" /* webpackChunkName: "component---src-pages-styles-color-theme-colors-mdx" */),
  "component---src-pages-styles-color-ui-mdx": () => import("./../../../src/pages/styles/color/ui.mdx" /* webpackChunkName: "component---src-pages-styles-color-ui-mdx" */),
  "component---src-pages-styles-iconography-anatomy-mdx": () => import("./../../../src/pages/styles/iconography/anatomy.mdx" /* webpackChunkName: "component---src-pages-styles-iconography-anatomy-mdx" */),
  "component---src-pages-styles-iconography-developer-mdx": () => import("./../../../src/pages/styles/iconography/developer.mdx" /* webpackChunkName: "component---src-pages-styles-iconography-developer-mdx" */),
  "component---src-pages-styles-iconography-icons-mdx": () => import("./../../../src/pages/styles/iconography/icons.mdx" /* webpackChunkName: "component---src-pages-styles-iconography-icons-mdx" */),
  "component---src-pages-styles-iconography-index-mdx": () => import("./../../../src/pages/styles/iconography/index.mdx" /* webpackChunkName: "component---src-pages-styles-iconography-index-mdx" */),
  "component---src-pages-styles-illustration-index-mdx": () => import("./../../../src/pages/styles/illustration/index.mdx" /* webpackChunkName: "component---src-pages-styles-illustration-index-mdx" */),
  "component---src-pages-styles-illustration-introduction-mdx": () => import("./../../../src/pages/styles/illustration/introduction.mdx" /* webpackChunkName: "component---src-pages-styles-illustration-introduction-mdx" */),
  "component---src-pages-styles-illustration-regulation-mdx": () => import("./../../../src/pages/styles/illustration/regulation.mdx" /* webpackChunkName: "component---src-pages-styles-illustration-regulation-mdx" */),
  "component---src-pages-styles-index-mdx": () => import("./../../../src/pages/styles/index.mdx" /* webpackChunkName: "component---src-pages-styles-index-mdx" */),
  "component---src-pages-styles-typography-brand-mdx": () => import("./../../../src/pages/styles/typography/brand.mdx" /* webpackChunkName: "component---src-pages-styles-typography-brand-mdx" */),
  "component---src-pages-styles-typography-index-mdx": () => import("./../../../src/pages/styles/typography/index.mdx" /* webpackChunkName: "component---src-pages-styles-typography-index-mdx" */),
  "component---src-pages-styles-typography-ui-mdx": () => import("./../../../src/pages/styles/typography/ui.mdx" /* webpackChunkName: "component---src-pages-styles-typography-ui-mdx" */)
}

