import SEO from "../../../../src/components/SEO";
import { TextLink } from '@openameba/spindle-ui/TextLink';
import { Openblank } from '@openameba/spindle-ui/Icon';
import * as style from '../../../../node_modules/@openameba/spindle-ui/TextLink/TextLink.css';
import * as React from 'react';
export default {
  SEO,
  TextLink,
  Openblank,
  style,
  React
};