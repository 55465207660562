import * as React from 'react';

function Button(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      {...props}
    >
      <path d="M2 7.5c0-2.2 1.8-4 4-4h12c2.2 0 4 1.8 4 4s-1.8 4-4 4H6c-2.2 0-4-1.8-4-4Zm20 10c0 2.21-1.79 4-4 4H6c-2.21 0-4-1.79-4-4s1.79-4 4-4h12c2.21 0 4 1.79 4 4Zm-2 0c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2s.9 2 2 2h12c1.1 0 2-.9 2-2Z" />
    </svg>
  );
}

export default Button;
