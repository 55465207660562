import * as React from 'react';

function Checkbox(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      {...props}
    >
      <path d="M16 5H8C6.34 5 5 6.34 5 8v8c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3Zm.71 5.21-4.36 4.36a1.993 1.993 0 0 1-2.68.14l-2.05-1.68a1 1 0 0 1-.14-1.41 1 1 0 0 1 1.41-.14l2.05 1.68L15.3 8.8a.996.996 0 1 1 1.41 1.41Z" />
    </svg>
  );
}

export default Checkbox;
