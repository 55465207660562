import React from 'react';
import { Link } from 'gatsby';
import { ChevronRightBold } from '@openameba/spindle-ui/Icon';

import * as style from './ContentsList.module.css';

type ContentsList = {
  url: string;
  title: string;
  enTitle: string;
  summary: string;
};

interface Props {
  list: ContentsList[];
}

const ContentsList: React.VFC<Props> = ({ list }) => {
  return (
    <ul className={style.list}>
      {list.map((item) => (
        <li className={style.item} key={item.url}>
          <h2 className={style.heading}>
            <span className={style.title}>{item.title}</span>
            <span lang="en" className={style.enTitle}>
              {item.enTitle}
            </span>
          </h2>
          <div className={style.inner}>
            <p className={style.summary}>{item.summary}</p>
            <p className={style.linkWrap}>
              <Link className={style.link} to={item.url}>
                詳細を見る
                <ChevronRightBold
                  className={style.libraryArrow}
                  aria-hidden="true"
                />
              </Link>
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ContentsList;
