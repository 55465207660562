import SEO from "../../../../src/components/SEO";
import PanelNav from "../../../../src/components/PanelNav";
import ContentsList from "../../../../src/components/ContentsList";
import { Abemakun } from '@openameba/spindle-ui/Icon';
import * as React from 'react';
export default {
  SEO,
  PanelNav,
  ContentsList,
  Abemakun,
  React
};