import PanelNav from "../../../../src/components/PanelNav";
import { ColorIcon, IconographyIcon, TypographyIcon, AnimationIcon } from "../../../../src/components/Icon";
import Nice from '@openameba/spindle-ui/Icon/Nice';
import * as React from 'react';
export default {
  PanelNav,
  ColorIcon,
  IconographyIcon,
  TypographyIcon,
  AnimationIcon,
  Nice,
  React
};