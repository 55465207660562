import SEO from "../../../../src/components/SEO";
import PanelNav from "../../../../src/components/PanelNav";
import ContentsList from "../../../../src/components/ContentsList";
import PageTitle from "../../../../src/components/PageTitle";
import Flash from '@openameba/spindle-ui/Icon/Flash';
import * as React from 'react';
export default {
  SEO,
  PanelNav,
  ContentsList,
  PageTitle,
  Flash,
  React
};