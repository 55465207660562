import * as React from 'react';

function List(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      {...props}
    >
      <path d="M20 8.12H4c-.55 0-1-.45-1-1s.45-1 1-1h16c.55 0 1 .45 1 1s-.45 1-1 1Zm1 3.67c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1h16c.55 0 1-.45 1-1Zm-8.5 4.72c0-.55-.44-1-.99-1.01l-7.5-.05H4c-.55 0-1 .44-1 .99s.44 1 .99 1.01l7.5.05h.01c.55 0 1-.44 1-.99Z" />
    </svg>
  );
}

export default List;
