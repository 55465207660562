import * as React from 'react';

function Typography(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      {...props}
    >
      <path d="M7.31 4.5c-1.82 0-3.4 1.13-4.01 2.74-.28.72-.3 1.43-.3 2.37v6.22c0 .19 0 .38.05.52.14.43.56.75 1.02.75.49 0 .9-.31 1.04-.75.05-.14.05-.33.05-.52v-2.47h4.27v2.47c0 .19 0 .38.05.52a1.081 1.081 0 0 0 2.06 0c.03-.14.05-.33.05-.52V9.61c0-.94-.02-1.65-.3-2.36A4.23 4.23 0 0 0 7.31 4.5Zm2.13 6.67H5.17V9.24c0-.54.03-.95.17-1.27a2.14 2.14 0 0 1 3.92 0c.15.32.18.73.18 1.27v1.93Zm11.53 2.06c-.02-1.82-1.62-3.21-3.68-3.21-1.84 0-3 1.02-3.32 1.34-.4.4-.4 1.06 0 1.46.33.33.82.36 1.22.17.14-.07.31-.24.45-.35.28-.24.85-.56 1.65-.56.82 0 1.46.4 1.6.95-.54.28-1.48.5-1.98.63-.82.19-3.3.78-3.3 3.16 0 1.77 1.65 3.21 3.68 3.21 2 0 3.66-1.44 3.68-3.2.04-1.17.04-2.31 0-3.6Zm-2.05 3.58c-.05.56-.68 1.16-1.63 1.16-.89 0-1.63-.52-1.63-1.15 0-.26 0-.75 1.72-1.16.56-.12 1.08-.26 1.55-.42.01.53.01 1.05-.01 1.57Z" />
    </svg>
  );
}

export default Typography;
