import React, { useState, useRef } from 'react';
import Openblank from '@openameba/spindle-ui/Icon/Openblank';
import PauseBold from '@openameba/spindle-ui/Icon/PauseBold';
import PlayFill from '@openameba/spindle-ui/Icon/PlayFill';
import '../../node_modules/@openameba/spindle-ui/LinkButton/LinkButton.css';

import * as style from './BrandMovie.module.css';

const BrandMovie: React.VFC = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current && videoRef.current.pause();
    } else {
      videoRef.current && videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className={style.root}>
      <div className={style.videoWrap}>
        <video
          ref={videoRef}
          poster="/images/brand-movie-poster.webp"
          src="/video/brand-movie.mp4"
          muted
          autoPlay
          loop
          playsInline
          className={style.video}
        ></video>
      </div>
      <div className={style.logoWrap}>
        <img
          src="/images/logo-ameba-life.webp"
          alt="つくる、つむぐ、つづく、 AmebaLIFE"
          className={style.logo}
          width="280"
          height="84"
        />
      </div>
      <div className={style.linkWrap}>
        <a
          className={`spui-LinkButton spui-LinkButton--small spui-LinkButton--outlined ${style.linkButtonInverse}`}
          href="https://content.ameba.jp/amebalife"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="spui-LinkButton-icon spui-LinkButton-icon--small">
            <Openblank className={style.iconOpenBlank} aria-hidden="true" />
          </span>
          Amebaブランドサイトへ
        </a>
      </div>
      <button className={style.button} type="button" onClick={handlePlayPause}>
        <span className={`${isPlaying ? style.playHidden : ''}`}>
          <PlayFill className={style.iconPlay} aria-hidden="true" />
          <span className={style.labelHidden}>play</span>
        </span>
        <span className={`${isPlaying ? '' : style.pauseHidden}`}>
          <PauseBold className={style.iconPause} aria-hidden="true" />
          <span className={style.labelHidden}>pause</span>
        </span>
      </button>
    </div>
  );
};

export default BrandMovie;
