import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import * as style from './IconList.module.css';

export default function IconList() {
  const { allFile } = useStaticQuery<GatsbyTypes.SpindleIconListQuery>(query);
  return (
    <ul className={style.list}>
      {allFile.edges.map(({ node }) => {
        if (!node.publicURL) {
          return null;
        }

        return (
          <li className={style.item} key={node.name}>
            <div className={style.icon}>
              <img
                alt=""
                className="theme--inverse"
                src={node.publicURL}
                height="24"
                width="24"
                loading="lazy"
              />
            </div>
            <span className={style.name}>{node.name}</span>
          </li>
        );
      })}
    </ul>
  );
}

export const query = graphql`
  query SpindleIconList {
    allFile(filter: { sourceInstanceName: { eq: "spindle-icons" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;
