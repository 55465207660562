import * as React from 'react';

function Iconography(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      {...props}
    >
      <path d="M11.47 6.49 8.25 3.54c-.48-.44-1.21-.44-1.69 0L3.35 6.49c-.72.65-.25 1.85.72 1.85v1.25c0 .69.56 1.25 1.25 1.25h4.17c.69 0 1.25-.56 1.25-1.25V8.34c.98 0 1.44-1.2.73-1.85ZM7.41 8.96a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm7.43-4.08c0-1.04.84-1.88 1.88-1.88s1.88.84 1.88 1.88-.84 1.88-1.88 1.88-1.88-.85-1.88-1.88Zm4.87 6.06a.83.83 0 0 0 .57-1.06c-.41-1.19-1.44-2.29-3.56-2.29s-3.15 1.09-3.56 2.29c-.15.45.11.93.57 1.06 1.95.55 4.02.55 5.98 0Zm.16 5-1.8-1.8a.387.387 0 0 1 0-.56l.34-.34c.31-.31.82-.31 1.13 0l1.23 1.23c.31.31.31.82 0 1.13l-.34.34c-.16.16-.41.16-.56 0Zm-2.82-1.34-3.39 3.39-.58 2.44c-.07.3.2.57.5.5l2.44-.58 3.39-3.39c.08-.08.08-.21 0-.3l-2.07-2.07a.215.215 0 0 0-.29.01Zm-6.12.09h-.42c-.12 0-.21.09-.21.21v3.96c0 .23-.19.42-.42.42-.23 0-.42-.19-.42-.42v-5.01c0-.23-.19-.42-.42-.42H3.83c-.23 0-.42.19-.42.42v5.43c0 .92.75 1.67 1.67 1.67h4.59c.92 0 1.67-.75 1.67-1.67v-4.17a.402.402 0 0 0-.41-.42Zm-4.8 5.01H4.88c-.23 0-.42-.19-.42-.42 0-.23.19-.42.42-.42h1.25c.23 0 .42.19.42.42 0 .23-.19.42-.42.42Zm1.46-1.67H4.88c-.23 0-.42-.19-.42-.42 0-.23.19-.42.42-.42h2.71c.23 0 .42.19.42.42 0 .23-.19.42-.42.42Zm.42-2.06c0 .21-.17.38-.38.38H4.85a.38.38 0 0 1-.38-.38v-1.11c0-.21.17-.38.38-.38h2.78c.21 0 .38.17.38.38v1.11Z" />
    </svg>
  );
}

export default Iconography;
