import * as React from 'react';

function Animation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      {...props}
    >
      <path d="M22.0004 6C22.0004 8.20914 20.2095 10 18.0004 10C15.7912 10 14.0004 8.20914 14.0004 6C14.0004 3.79086 15.7912 2 18.0004 2C20.2095 2 22.0004 3.79086 22.0004 6ZM11.0033 21.2998C11.5971 21.293 12.1156 20.9521 12.3578 20.4092L15.9144 12.4062C16.1381 11.9014 15.9115 11.3105 15.4066 11.0859C14.9047 10.8623 14.3119 11.0889 14.0863 11.5938L10.973 18.5996L9.41151 15.2939C8.91737 14.2471 7.891 13.5879 6.7328 13.5752H6.69764C5.55409 13.5752 4.5287 14.208 4.016 15.2334L2.85585 17.5527C2.60878 18.0469 2.80897 18.6475 3.30311 18.8945C3.79725 19.1396 4.39784 18.9414 4.64491 18.4473L5.80506 16.1279C5.97987 15.7773 6.35194 15.582 6.71034 15.5752C7.10194 15.5791 7.43592 15.793 7.60292 16.1475L9.63026 20.4404C9.88124 20.9717 10.3998 21.2998 10.9857 21.2998H11.0033Z" />
    </svg>
  );
}

export default Animation;
