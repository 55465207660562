import * as React from 'react';

function TextField(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      {...props}
    >
      <path d="M20 9.5H4c-1.1 0-2 .9-2 2v3.95c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V11.5c0-1.1-.9-2-2-2ZM4 15.45V11.5h16v3.95H4ZM2 7c0-.55.45-1 1-1h9.5c.55 0 1 .45 1 1s-.45 1-1 1H3c-.55 0-1-.45-1-1Z" />
    </svg>
  );
}

export default TextField;
