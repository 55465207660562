import * as React from 'react';

function Color(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.52 4.65C9.07 2.8 3 6.65 2.07 10.88c-1.63 7.46 7.49 10.56 13.08 8.61 3.02-1.09 3.04-3.32 2.05-4.44-1.1-1.24-.64-3.46 1.48-2.66 2.11.8 2.79.25 3.13-.75.58-2.01-1.95-5.83-6.29-6.99ZM5.88 15.54c-.73-.01-1.32-.61-1.31-1.34.01-.73.61-1.32 1.34-1.31.73.01 1.32.61 1.31 1.34-.01.73-.61 1.31-1.34 1.31Zm.93-3.79c-.73-.01-1.32-.61-1.31-1.34 0-.73.6-1.32 1.33-1.31.73.01 1.32.61 1.31 1.34 0 .73-.6 1.32-1.33 1.31Zm3.15-2.2c-.73-.01-1.32-.61-1.31-1.34.01-.73.61-1.32 1.34-1.31.73.01 1.32.61 1.31 1.34-.01.73-.6 1.32-1.34 1.31Zm3.93-.3c-.73-.01-1.32-.61-1.31-1.34.01-.73.61-1.32 1.34-1.31.73.01 1.32.61 1.31 1.34-.01.73-.61 1.32-1.34 1.31Z"
      />
    </svg>
  );
}

export default Color;
