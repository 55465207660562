import * as React from 'react';

function Modal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      {...props}
    >
      <path d="M13 13c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1s.45-1 1-1h4c.55 0 1 .45 1 1Zm3-5H8c-.55 0-1 .45-1 1s.45 1 1 1h8c.55 0 1-.45 1-1s-.45-1-1-1Zm1-5H7C4.79 3 3 4.69 3 6.76V21c0 .55.45 1 1 1s1-.45 1-1V6.76C5 5.79 5.9 5 7 5h10c1.1 0 2 .79 2 1.76V21c0 .55.45 1 1 1s1-.45 1-1V6.76C21 4.69 19.21 3 17 3Z" />
    </svg>
  );
}

export default Modal;
